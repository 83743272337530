import * as React from 'react';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { StripColumnsContainerProps } from '../StripColumnsContainer.types';
import { TestIds } from '../constants';
import styles from './style/StripColumnsContainer.scss';

const StripColumnsContainer: React.FC<StripColumnsContainerProps> = ({
  id,
  fillLayers,
  children,
}) => {
  return (
    <section id={id}>
      <FillLayers {...fillLayers} />
      <div data-testid={TestIds.columns} className={styles.columns}>
        {children()}
      </div>
    </section>
  );
};

export default StripColumnsContainer;
