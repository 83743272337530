import * as React from 'react';
import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import styles from './WPhotoWrapper.scss';

type WPhotoWrapperProps = {
  id: string;
  className: string;
  title?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  onDblClick?: MouseEventHandler<HTMLElement>;
  withOnClickHandler: boolean;
};

export const WPhotoWrapper: React.FC<WPhotoWrapperProps> = props => {
  const {
    id,
    children,
    className,
    title,
    onClick,
    onDblClick,
    withOnClickHandler,
  } = props;
  const withOnClickHandlerClass = withOnClickHandler
    ? styles.withOnClickHandler
    : '';

  return (
    <div
      id={id}
      className={classNames(className, withOnClickHandlerClass)}
      title={title}
      onClick={onClick}
      onDoubleClick={onDblClick}
    >
      {children}
    </div>
  );
};
