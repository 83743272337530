import React, { FunctionComponent } from 'react';
import { MediaContainerCompProps } from '../MediaContainer.types';
import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import styles from './style/MediaContainer.scss';

const MediaContainer: FunctionComponent<MediaContainerCompProps> = ({
  id,
  fillLayers,
  children,
  meshProps,
}) => {
  return (
    <div id={id} className={styles.mediaContainer}>
      <FillLayers {...fillLayers} />
      <MeshContainer id={id} {...meshProps}>
        {children}
      </MeshContainer>
    </div>
  );
};

export default MediaContainer;
