import * as React from 'react';
import { IContainerLogicProps } from '../../Container.types';
import MeshContainer from '../../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';

export const ContainerLogic: React.FC<IContainerLogicProps> = props => {
  const { id, className, meshProps, renderSlot, children } = props;

  const meshContainerProps = {
    id,
    children,
    ...meshProps,
  };

  return (
    <div id={id} className={className}>
      {renderSlot({
        containerChildren: <MeshContainer {...meshContainerProps} />,
      })}
    </div>
  );
};
